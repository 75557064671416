<template>
  <div class="cpi-application-list">
    <v-row>
      <v-col class="d-flex align-center pt-10">
        <h4 class="mb-0 aon-gray-01--text">
          {{ $t('cpiApplicationList.title') }}
        </h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <div>
          <TableFiltersSelect
            :active-filters="activeFilters"
            :filter-types="filterTypes"
            :chip-titles="true"
            bottom-chips
            @change="getCpiApplications"
          />
        </div>
      </v-col>
      <v-col class="text-right">
        <router-link
          :to="{
            name: 'CPI Application',
            params: { applicationId: 'new-application' },
          }"
        >
          <AppButton>
            {{ $t('cpiApplicationList.newApplication') }}
          </AppButton>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-sheet class="elevation-0">
          <v-data-table
            :headers="cpiApplicationListHeaders"
            :items="cpiApplicationList"
            :loading="loading"
            :options.sync="pagingOptions"
            :server-items-length="totalCpiApplications"
            :footer-props="{
              'items-per-page-options': [5, 10, 15, 25, 50],
            }"
            :expanded="expanded"
          >
            <template #item.applicantName="{ item }">
              <router-link
                :to="{
                  name: 'CPI Application',
                  params: { applicationId: item.id },
                }"
                class="applicant-name"
              >
                {{ item.applicantName }}
              </router-link>
            </template>

            <template #item.status="{ item }">
              <div class="d-flex py-2">
                <v-chip
                  class="left-chip py-4"
                  :color="`${getStatusColor(item.status)}-background`"
                  :text-color="`${getStatusColor(item.status)}-dark`"
                  small
                >
                  {{ $t(`cpiApplicationList.status.${item.status}.step`) }}
                </v-chip>
                <v-chip class="right-chip py-4 forced-shadow" small>
                  {{ $t(`cpiApplicationList.status.${item.status}.name`) }}
                </v-chip>
              </div>
            </template>

            <template #item.creditRating="{ item }">
              <v-badge
                :color="getBadgeColor(item.creditRating)"
                inline
                left
                dot
              ></v-badge>
              {{ $t(`cpiApplicationList.creditRating.${item.creditRating}`) }}
            </template>

            <template #item.ipRating="{ item }">
              <v-badge
                :color="getBadgeColor(item.ipRating)"
                inline
                left
                dot
              ></v-badge>
              {{ $t(`cpiApplicationList.ipRating.${item.ipRating}`) }}
            </template>

            <template #item.tier="{ item }">
              <div v-if="item.tier != null">
                <v-badge
                  :color="getBadgeColor(`TIER_${item.tier}`)"
                  inline
                  left
                  dot
                ></v-badge>
                {{ $t(`cpiApplicationList.combinedScore.tier${item.tier}`) }}
              </div>
              <div v-else-if="item.systemRecommendation != null">
                <v-badge
                  :color="getBadgeColor(`TIER_${item.systemRecommendation}`)"
                  inline
                  left
                  dot
                ></v-badge>
                {{
                  $t(
                    `cpiApplicationList.combinedScore.tier${item.systemRecommendation}`
                  )
                }}
              </div>
            </template>

            <template #item.created="{ item }">
              <div class="table-date-item">
                {{ getDateDisplay(item.created) }}
              </div>
            </template>

            <template #item.modified="{ item }">
              <div class="table-date-item">
                {{ getDateDisplay(item.modified) }}
              </div>
            </template>

            <template #expanded-item="{ headers, item }">
              <td v-if="approvedOrRejected(item)" :colspan="headers.length">
                <CpiApplicationListAlert :application="item" />
              </td>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TableFiltersSelect from '@aon/cfs-components/src/components/DataTables/TableFiltersSelect';
import { mapState } from 'vuex';
import { getUtcDateInLocal } from '@aon/cfs-utils';
import CpiApplicationListAlert from '@/views/CpiLending/CpiApplicationListAlert';

export default {
  name: 'CpiApplicationList',
  components: { CpiApplicationListAlert, TableFiltersSelect },
  data() {
    return {
      activeFilters: [],
      cpiApplicationListHeaders: [
        {
          sortable: false,
          value: 'applicantName',
          text: this.$t('cpiApplicationList.headers.applicantName'),
          width: 125,
        },
        {
          sortable: false,
          value: 'status',
          text: this.$t('cpiApplicationList.headers.status'),
        },
        {
          sortable: false,
          value: 'creditRating',
          text: this.$t('cpiApplicationList.headers.creditRating'),
        },
        {
          sortable: false,
          value: 'ipRating',
          text: this.$t('cpiApplicationList.headers.ipRating'),
        },
        {
          sortable: false,
          value: 'tier',
          text: this.$t('cpiApplicationList.headers.combinedScore'),
        },
        {
          sortable: false,
          value: 'created',
          text: this.$t('cpiApplicationList.headers.created'),
        },
        {
          sortable: false,
          value: 'modified',
          text: this.$t('cpiApplicationList.headers.modified'),
        },
      ],
      errorMessage: '',
      loading: false,
      pagingOptions: null,
      totalCpiApplications: 0,
      approvedOrRejectedStatuses: ['APPROVED', 'REJECTED'],
      filterTypes: [
        {
          typeText: this.$t('cpiApplicationList.filters.status.title'),
          typeValue: 'status',
          values: [
            {
              text: this.$t('cpiApplicationList.filters.status.DRAFT'),
              value: 'DRAFT',
            },
            {
              text: this.$t('cpiApplicationList.filters.status.IP_REVIEW'),
              value: 'IP_REVIEW',
            },
            {
              text: this.$t('cpiApplicationList.filters.status.FINAL_ANALYSIS'),
              value: 'FINAL_ANALYSIS',
            },
            {
              text: this.$t(
                'cpiApplicationList.filters.status.DEAL_PRIORITIZATION'
              ),
              value: 'DEAL_PRIORITIZATION',
            },
            {
              text: this.$t('cpiApplicationList.filters.status.APPROVED'),
              value: 'APPROVED',
            },
            {
              text: this.$t('cpiApplicationList.filters.status.REJECTED'),
              value: 'REJECTED',
            },
            {
              text: this.$t('cpiApplicationList.filters.status.ARCHIVED'),
              value: 'ARCHIVED',
            },
          ],
        },
        {
          typeText: this.$t('cpiApplicationList.filters.creditRating.title'),
          typeValue: 'creditRating',
          values: [
            {
              text: this.$t(
                'cpiApplicationList.filters.creditRating.EXCELLENT'
              ),
              value: 'EXCELLENT',
            },
            {
              text: this.$t('cpiApplicationList.filters.creditRating.ADEQUATE'),
              value: 'ADEQUATE',
            },
            {
              text: this.$t('cpiApplicationList.filters.creditRating.POOR'),
              value: 'POOR',
            },
          ],
        },
        {
          typeText: this.$t('cpiApplicationList.ipRating.title'),
          typeValue: 'ipRating',
          values: [
            {
              text: this.$t('cpiApplicationList.ipRating.EXCELLENT'),
              value: 'EXCELLENT',
            },
            {
              text: this.$t('cpiApplicationList.ipRating.ADEQUATE'),
              value: 'ADEQUATE',
            },
            {
              text: this.$t('cpiApplicationList.ipRating.POOR'),
              value: 'POOR',
            },
          ],
        },
        {
          typeText: this.$t('cpiApplicationList.combinedScore.title'),
          typeValue: 'tier',
          values: [
            {
              text: this.$t('cpiApplicationList.combinedScore.tier1'),
              value: '1',
            },
            {
              text: this.$t('cpiApplicationList.combinedScore.tier2'),
              value: '2',
            },
            {
              text: this.$t('cpiApplicationList.combinedScore.tier3'),
              value: '3',
            },
            {
              text: this.$t('cpiApplicationList.combinedScore.tier4'),
              value: '4',
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState('cpiApplications', {
      cpiApplicationList: state => state.cpiApplications,
    }),
    expanded() {
      return this.cpiApplicationList.filter(item =>
        this.approvedOrRejected(item)
      );
    },
  },
  watch: {
    pagingOptions: {
      handler() {
        this.getCpiApplications();
      },
      deep: true,
    },
  },
  methods: {
    getDateDisplay(date) {
      return getUtcDateInLocal(date);
    },
    async getCpiApplications(filters = {}) {
      this.loading = true;

      try {
        const result = await this.$store.dispatch(
          'cpiApplications/listCpiApplications',
          {
            page: this.pagingOptions.page,
            pageSize: this.pagingOptions.itemsPerPage,
            ...filters,
          }
        );

        this.totalCpiApplications = result.rowCount;
      } catch (error) {
        this.$AppEventBus.$emit('snacktime', {
          type: 'error',
          message: this.$t('errors.unknown'),
        });
      } finally {
        this.loading = false;
      }
    },
    approvedOrRejected(item) {
      return this.approvedOrRejectedStatuses.includes(item.status);
    },
    getStatusColor(status) {
      switch (status) {
        case 'APPROVED':
          return 'success';
        case 'REJECTED':
          return 'error';
        default:
          return 'info';
      }
    },
    getBadgeColor(value) {
      switch (value) {
        case 'EXCELLENT':
        case 'TIER_1':
          return 'success';
        case 'ADEQUATE':
        case 'TIER_2':
          return 'warning';
        case 'POOR':
        case 'TIER_3':
        case 'TIER_4':
          return 'error';
        default:
          return 'default';
      }
    },
  },
};
</script>

<style lang="scss">
.cpi-application-list {
  .v-data-table {
    border-radius: $sheet-border-radius;
    border: $border-gray-05;

    .left-chip {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 32px;
      border-bottom-left-radius: 32px;
    }
    .right-chip {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 32px;
      border-bottom-right-radius: 32px;
      box-shadow: inset 0 0 0 1px $aon-gray-05;
      background-color: transparent;
    }

    .v-data-table__expand-icon {
      display: none;
    }
    .v-data-table__expanded {
      &.v-data-table__expanded__row {
        td:first-child {
          border-left: 5px solid $warning;
        }
        .text-start {
          border-bottom: none !important;
        }
      }

      &.v-data-table__expanded__content {
        box-shadow: none;
        td:first-child {
          border-left: 5px solid $warning;
        }
      }
    }

    .v-data-table-header tr {
      height: 64px;

      th {
        &:not(:last-child) {
          border-right: $border-gray-05;
        }

        span {
          color: $aon-gray-01;
          font-size: 1rem;
          font-weight: 700;
        }
      }
    }
  }

  .table-date-item {
    color: $aon-gray-02;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr {
    &:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
      background: $aon-gray-08;
    }

    td:first-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 250px;
    }
  }

  .v-chip {
    &.draft-chip,
    &.ip-review-chip,
    &.deal-prioritization-chip {
      background: $aon-teal-light !important;
      color: $aon-teal;

      .v-icon {
        color: $aon-teal;
      }
    }
    &.adequate-chip,
    &.\32-chip {
      background: $warning-light !important;
      color: $warning-dark;

      .v-icon {
        color: $warning-dark;
      }
    }
    &.approved-chip,
    &.excellent-chip,
    &.\31-chip {
      background: $success-light !important;
      color: $success-dark;

      .v-icon {
        color: $success-dark;
      }
    }
    &.rejected-chip,
    &.poor-chip,
    &.\33-chip,
    &.\34-chip {
      background: $error-light !important;
      color: $error-dark;

      .v-icon {
        color: $error-dark;
      }
    }
  }

  .applicant-name {
    font-size: 1rem;
  }
}
.v-menu__content {
  width: 250px !important;
}
.grid-filters-menu .filter-list .filter-list-value.active {
  background-color: $aon-gray-06;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cpi-application-list"},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center pt-10"},[_c('h4',{staticClass:"mb-0 aon-gray-01--text"},[_vm._v(" "+_vm._s(_vm.$t('cpiApplicationList.title'))+" ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('div',[_c('TableFiltersSelect',{attrs:{"active-filters":_vm.activeFilters,"filter-types":_vm.filterTypes,"chip-titles":true,"bottom-chips":""},on:{"change":_vm.getCpiApplications}})],1)]),_c('v-col',{staticClass:"text-right"},[_c('router-link',{attrs:{"to":{
          name: 'CPI Application',
          params: { applicationId: 'new-application' },
        }}},[_c('AppButton',[_vm._v(" "+_vm._s(_vm.$t('cpiApplicationList.newApplication'))+" ")])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-sheet',{staticClass:"elevation-0"},[_c('v-data-table',{attrs:{"headers":_vm.cpiApplicationListHeaders,"items":_vm.cpiApplicationList,"loading":_vm.loading,"options":_vm.pagingOptions,"server-items-length":_vm.totalCpiApplications,"footer-props":{
            'items-per-page-options': [5, 10, 15, 25, 50],
          },"expanded":_vm.expanded},on:{"update:options":function($event){_vm.pagingOptions=$event}},scopedSlots:_vm._u([{key:"item.applicantName",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{staticClass:"applicant-name",attrs:{"to":{
                name: 'CPI Application',
                params: { applicationId: item.id },
              }}},[_vm._v(" "+_vm._s(item.applicantName)+" ")])]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex py-2"},[_c('v-chip',{staticClass:"left-chip py-4",attrs:{"color":((_vm.getStatusColor(item.status)) + "-background"),"text-color":((_vm.getStatusColor(item.status)) + "-dark"),"small":""}},[_vm._v(" "+_vm._s(_vm.$t(("cpiApplicationList.status." + (item.status) + ".step")))+" ")]),_c('v-chip',{staticClass:"right-chip py-4 forced-shadow",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.$t(("cpiApplicationList.status." + (item.status) + ".name")))+" ")])],1)]}},{key:"item.creditRating",fn:function(ref){
              var item = ref.item;
return [_c('v-badge',{attrs:{"color":_vm.getBadgeColor(item.creditRating),"inline":"","left":"","dot":""}}),_vm._v(" "+_vm._s(_vm.$t(("cpiApplicationList.creditRating." + (item.creditRating))))+" ")]}},{key:"item.ipRating",fn:function(ref){
              var item = ref.item;
return [_c('v-badge',{attrs:{"color":_vm.getBadgeColor(item.ipRating),"inline":"","left":"","dot":""}}),_vm._v(" "+_vm._s(_vm.$t(("cpiApplicationList.ipRating." + (item.ipRating))))+" ")]}},{key:"item.tier",fn:function(ref){
              var item = ref.item;
return [(item.tier != null)?_c('div',[_c('v-badge',{attrs:{"color":_vm.getBadgeColor(("TIER_" + (item.tier))),"inline":"","left":"","dot":""}}),_vm._v(" "+_vm._s(_vm.$t(("cpiApplicationList.combinedScore.tier" + (item.tier))))+" ")],1):(item.systemRecommendation != null)?_c('div',[_c('v-badge',{attrs:{"color":_vm.getBadgeColor(("TIER_" + (item.systemRecommendation))),"inline":"","left":"","dot":""}}),_vm._v(" "+_vm._s(_vm.$t( ("cpiApplicationList.combinedScore.tier" + (item.systemRecommendation)) ))+" ")],1):_vm._e()]}},{key:"item.created",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"table-date-item"},[_vm._v(" "+_vm._s(_vm.getDateDisplay(item.created))+" ")])]}},{key:"item.modified",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"table-date-item"},[_vm._v(" "+_vm._s(_vm.getDateDisplay(item.modified))+" ")])]}},{key:"expanded-item",fn:function(ref){
              var headers = ref.headers;
              var item = ref.item;
return [(_vm.approvedOrRejected(item))?_c('td',{attrs:{"colspan":headers.length}},[_c('CpiApplicationListAlert',{attrs:{"application":item}})],1):_vm._e()]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }